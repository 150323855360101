import React from 'react';
import { Control, Controller, FieldValues, Path } from 'react-hook-form';
import ReactSelect, { MultiValueProps } from 'react-select';
import { Option } from '../utils/generic';

type OptionType = { label: string; value: boolean | number | string };

const MultiValue = ({ index, getValue }: MultiValueProps<OptionType, true>) => {
  const values = getValue();

  if (values.length === 1) {
    const value = values[0];
    return <div>{String(value.label)}</div>;
  }
  if (index === 0 && values.length > 1) {
    return <div>{`${values.length} items selected`}</div>;
  }

  return null;
};

export const Select = <T extends FieldValues>({
  id,
  control,
  name,
  label,
  placeholder,
  options,
  error,
  inline = false,
  className,
  multiple = false,
}: {
  id: string;
  control: Control<T>;
  title: string;
  name: Path<T>;
  label?: string;
  placeholder?: string;
  options: Option<string | number | boolean>[];
  error?: string;
  inline?: boolean;
  className?: string;
  multiple?: boolean;
}) => {
  const baseClassNames =
    'h-10 w-full flex items-center text-base text-color rounded-xl pl-4 py-2 border-2 bg-color cursor-pointer pr-8';
  return (
    <div className={`relative flex w-full gap-2 ${inline ? 'flex-row items-center' : 'flex-col'}`}>
      {label && <p className="ml-2">{label}</p>}
      <div className="relative">
        <Controller
          control={control}
          name={name}
          render={({ field: { onChange, value } }) => {
            if (multiple) {
              return (
                <ReactSelect
                  id={id}
                  instanceId={id}
                  isMulti={multiple}
                  options={options}
                  value={value.length > 0 ? options.find((option) => option.value === value) : []}
                  onChange={(options) => onChange(options.map((option) => option.value))}
                  menuPortalTarget={document.body}
                  unstyled={true}
                  placeholder={placeholder}
                  components={{ MultiValue }}
                  maxMenuHeight={246}
                  styles={{
                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    option: (baseStyles) => ({
                      ...baseStyles,
                      cursor: 'pointer',
                    }),
                  }}
                  classNames={{
                    control: (state) =>
                      `${className} ${baseClassNames} ${state.menuIsOpen ? 'border-primary' : 'border-neutral'}`,
                    dropdownIndicator: () => 'absolute right-2 bottom-2 text-color',
                    menu: () => 'bg-color border-blue border-2 rounded-xl overflow-hidden',
                    option: (state) =>
                      `${state.isSelected && 'bg-primary text-light'} px-4 py-2 hover:bg-neutral hover:text-dark`,
                    clearIndicator: () => 'cursor-pointer',
                    noOptionsMessage: () => 'py-2',
                  }}
                />
              );
            }
            return (
              <ReactSelect
                id={id}
                instanceId={id}
                options={options}
                value={options.find((option) => option.value === value)}
                onChange={(option) => onChange(option?.value)}
                menuPortalTarget={document.body}
                unstyled={true}
                placeholder={placeholder}
                maxMenuHeight={246}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  option: (baseStyles) => ({
                    ...baseStyles,
                    cursor: 'pointer',
                  }),
                }}
                classNames={{
                  control: (state) =>
                    `${className} ${baseClassNames} ${state.menuIsOpen ? 'border-blue' : 'border-neutral'}`,
                  dropdownIndicator: () => 'absolute text-color',
                  menu: () => 'bg-color border-blue border-2 rounded-xl overflow-hidden',
                  option: (state) =>
                    `${state.isSelected && 'bg-primary-lighter text-dark'} px-4 py-2 hover:bg-neutral hover:text-dark`,
                  noOptionsMessage: () => 'py-2',
                }}
              />
            );
          }}
        />
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
};

export default Select;
