import React, { useState } from 'react';
import { BiHide, BiShow } from 'react-icons/bi';

type TextInputProps = JSX.IntrinsicElements['input'] & {
  className?: string;
  overrideBaseClassnames?: boolean;
  label?: string;
  error?: string;
  formatValue?: (value: string) => string;
  callbackOnChange?: (name?: string) => void;
};

/**
 * The callbackOnChange prop allows you to pass a function that will be called
 * whenever the input value changes.
 * Note: callbackOnChange is not the same as react-hook-form clearErrors,
 * but you can pass a function that calls clearErrors internally.
 */
export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>((props, ref) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const { label, type, error, name, className, overrideBaseClassnames, formatValue, callbackOnChange, ...rest } = props;

  const baseClassNames = `${className} text-dark border-neutral bg-light focus:border-primary placeholder:text-neutral-dark h-10 w-full rounded-xl border-2 px-4 text-base outline-none`;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (formatValue) {
      e.target.value = formatValue(value);
    }
    if (callbackOnChange && name) {
      callbackOnChange(name);
    }
    if (props.onChange) {
      props.onChange(e);
    }
  };

  return (
    <div className="relative flex w-full flex-col gap-2">
      {label && <p className="ml-2">{label}</p>}
      <div className="relative">
        <input
          ref={ref}
          name={name}
          type={showPassword ? 'text' : type}
          className={`${overrideBaseClassnames ? className : baseClassNames} ${type === 'password' && 'pr-10'} ${
            !!error && 'border-error'
          }`}
          aria-invalid={!!error}
          {...rest}
          onChange={handleChange}
        />
        {type === 'password' && (
          <button
            type="button"
            className="absolute right-2 top-1/2 -translate-y-1/2 cursor-pointer p-2"
            onClick={() => setShowPassword(!showPassword)}
          >
            {!showPassword ? <BiHide className="text-neutral-dark" /> : <BiShow className="text-primary" />}
          </button>
        )}
      </div>
      {error && (
        <div id={`${name}-error`} className="ml-2 flex flex-col">
          <p className="text-error text-xs">{error}</p>
        </div>
      )}
    </div>
  );
});

TextInput.displayName = 'TextInput';

export default TextInput;
