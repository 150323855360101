import React from 'react';
import { Children } from '../utils';

const Accordion = ({
  children,
  onClick,
  accordionOpen,
  accordionChildren,
}: {
  children?: Children;
  onClick?: () => void;
  accordionOpen?: boolean;
  accordionChildren?: Children;
}) => {
  return (
    <>
      <button
        type="button"
        className={`${
          accordionOpen && 'border-b-2'
        } border-neutral flex min-h-20 w-full flex-row items-center justify-between  p-4 ${
          onClick && 'cursor-pointer'
        }`}
        onClick={onClick}
      >
        {children}
      </button>
      {accordionOpen && <div className="flex min-h-28 flex-row items-center p-4">{accordionChildren}</div>}
    </>
  );
};

export default Accordion;
