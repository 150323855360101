import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { MdClose } from 'react-icons/md';
import { Children } from '../utils';
import { Button } from './Buttons';

const modalRoot =
  typeof document !== 'undefined' ? document.getElementById('modal-root') || document.createElement('div') : null;

if (modalRoot) {
  modalRoot.id = 'modal-root';
  document.body.appendChild(modalRoot);
}

const Modal = ({
  onClose,
  id,
  maxWidth,
  children,
}: {
  onClose: () => void;
  id?: string;
  maxWidth?: string;
  children: Children;
}) => {
  useEffect(() => {
    document.body.style.overflowY = 'hidden';
    document.body.style.paddingRight = '5px';

    return () => {
      document.body.style.overflowY = 'scroll';
      document.body.style.paddingRight = '0';
    };
  }, []);

  if (!modalRoot) return;

  return createPortal(
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      <button
        type="button"
        className="bg-primary fixed inset-0 flex items-center justify-center opacity-20"
        onClick={onClose}
      />
      <div
        id={id || 'modal'}
        className={`${maxWidth} bg-color relative flex h-screen w-screen flex-col overflow-auto p-8 shadow sm:h-auto sm:max-h-[600px] sm:w-full sm:max-w-lg sm:rounded-xl`}
      >
        <button type="button" className="text-color absolute right-0 top-0 cursor-pointer p-2" onClick={onClose}>
          <MdClose size={24} />
        </button>
        {children}
      </div>
    </div>,
    modalRoot
  );
};

export default Modal;

export const RemoveUserFromCompanyModal = ({
  value,
  onClose,
  callback,
}: {
  value: string | undefined;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  return (
    <Modal id="remove-user-from-company-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Are you sure?</h1>
      <p className="mt-4">
        You are about to remove <span className="font-bold">{value}</span> from the company
      </p>
      <div className="mt-8 flex flex-row justify-between gap-4">
        <div className="w-48">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="w-48">
          <Button
            id="delete-confirm"
            style="delete"
            onClick={async () => {
              await callback();
              onClose();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export const DeleteModal = ({
  value,
  onClose,
  callback,
}: {
  value: string | undefined;
  onClose: () => void;
  callback: () => Promise<void>;
}) => {
  return (
    <Modal id="delete-modal" onClose={onClose}>
      <h1 className="font-header text-lg">Are you sure?</h1>
      <p className="mt-4">
        You are about to delete <span className="font-bold">{value}</span>
      </p>
      <div className="mt-8 flex flex-row justify-between gap-4">
        <div className="w-48">
          <Button style="secondary" onClick={onClose}>
            Cancel
          </Button>
        </div>
        <div className="w-48">
          <Button
            id="delete-confirm"
            style="delete"
            onClick={async () => {
              await callback();
              onClose();
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};
