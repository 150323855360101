import React from 'react';
import { MdOutlineCheck } from 'react-icons/md';

type CheckboxProps = Omit<JSX.IntrinsicElements['input'], 'type'> & { label?: string; labelclassname?: string };

const Checkbox = React.forwardRef<HTMLInputElement, CheckboxProps>((props, ref) => {
  const { id, label, labelclassname } = props;

  const baseClassNames = 'h-4 w-4 flex justify-center items-center border-2';

  return (
    <label className="relative flex cursor-pointer flex-row items-center gap-2" htmlFor={id}>
      <input type="checkbox" ref={ref} {...props} className="peer hidden" />
      <div
        className={`${baseClassNames} border-neutral peer-checked:border-primary peer-disabled:border-neutral cursor-pointer`}
      />
      <MdOutlineCheck
        size={12}
        className="text-primary peer-disabled:text-neutral absolute left-0.5 top-1/2 hidden -translate-y-1/2 peer-checked:block"
      />
      {label && <div className={`${labelclassname} text-color peer-disabled:text-neutral`}>{label}</div>}
    </label>
  );
});

Checkbox.displayName = 'Checkbox';

export default Checkbox;
