import React from 'react';
import { BarLoader } from 'react-spinners';
import { Children } from '../utils';

type ButtonProps = Omit<JSX.IntrinsicElements['button'], 'children' | 'style'> & {
  children?: Children;
  type?: 'reset' | 'submit';
  style?: 'primary' | 'secondary' | 'delete' | 'custom';
  loading?: boolean;
  overrideBaseClassnames?: boolean;
};

export const Button = (props: ButtonProps) => {
  const { children, type, className, style = 'primary', loading, disabled, overrideBaseClassnames, ...rest } = props;

  const baseClassNames = `${className} text-base font-header h-12 shadow rounded-[24px] flex items-center justify-center px-4 min-w-fit w-full`;

  const primaryStyleClassNames =
    'bg-light border-2 border-primary dark:border-none dark:bg-primary text-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:active:bg-dark-lighter active:bg-primary active:border-primary active:text-dark-dark disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-neutral-darkest dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest';

  const secondaryStyleClassNames =
    'bg-color text-primary border-2 border-blue hover:opacity-80 disabled:text-neutral-darker disabled:bg-neutral-lighter disabled:border-none dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest dark:disabled:border-none';

  const deleteStyleClassNames = 'bg-color text-secondary-dark border-2 border-secondary-dark';

  const customStyleClassNames =
    'bg-blue text-dark border-primary-lighter dark:bg-primary dark:text-light hover:bg-primary-lighter hover:border-primary-lighter dark:hover:bg-primary-dark dark:disabled:text-neutral-darktext dark:disabled:bg-dark-lightest h-12 w-full rounded-[24px] border-2 px-4 font-bold dark:border-none';

  return (
    <button
      {...rest}
      type={type || 'button'}
      className={`${overrideBaseClassnames ? className : baseClassNames} ${
        style === 'primary'
          ? primaryStyleClassNames
          : style === 'secondary'
            ? secondaryStyleClassNames
            : style === 'delete'
              ? deleteStyleClassNames
              : customStyleClassNames
      }`}
      disabled={loading || disabled}
    >
      {loading ? <BarLoader color="#2a9c8e" /> : <p>{children}</p>}
    </button>
  );
};

Button.displayName = 'Button';

export const IconButton = ({
  children,
  id,
  style = 'primary',
  onClick,
  disabled,
  className = '',
}: {
  children: JSX.Element;
  id?: string;
  style?: 'primary' | 'secondary' | 'delete';
  onClick?: () => void;
  disabled?: boolean;
  className?: string;
}) => {
  const baseClassNames =
    'rounded-full shadow border-2 h-10 w-10 flex justify-center items-center cursor-pointer hover:opacity-80';
  const primaryClassNames = 'border-primary bg-primary text-light';
  const secondaryClassNames = 'border-primary bg-transparent text-primary';
  const deleteStyleClassNames = 'bg-transparent text-error border-error';

  if (disabled) {
    return <div className="rounded-full">{children}</div>;
  }

  return (
    <button
      type="button"
      id={id}
      onClick={onClick}
      className={`${baseClassNames} ${
        style === 'primary' ? primaryClassNames : style === 'secondary' ? secondaryClassNames : deleteStyleClassNames
      } ${className}`}
    >
      {children}
    </button>
  );
};
